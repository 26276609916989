import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import ImageWMS from 'ol/source/ImageWMS';
import {Image as ImageLayer} from 'ol/layer';
import Group from 'ol/layer/Group';
import Overlay from 'ol/Overlay';
import Control from 'ol/control/Control';
import $ from 'jquery';

import { applicationType } from './appConfig'

//Instantiates the view using EPSG:3857 projection (WGS84 Web Mercator)
//EPSG:3857 was used as EPSG:4326 was disliked by the client due to its "squished" appearance.
//It is centered and zoomed to the center of the United Kingdom.
const view = new View({
  projection: 'EPSG:3857',
  center: [-500000, 7300000],
  zoom: 6
  })

//Instantiates the map using the view created above.
const map = new Map({
  target: 'map',
  view: view
});

//Adds the OpenStreetMap layer to the map.
const openStreetMap = new TileLayer({
  source: new OSM()
})
map.addLayer(openStreetMap);

// //Instantiates the postcode layer from the GeoServer WMS.
const tutorPostcodeSource = new ImageWMS({
  url: 'https://geo.flixprimeplus.net/geoserver/ps-data-postgis/wms',
  params: {'LAYERS': 'ps-data-postgis:pspostcode-full-uk', 'TILED': true, CQL_FILTER: "tutorname >= 'A'"},
  serverType: 'geoserver',
  crossOrigin: 'anonymous'
});

//Instantiates the an ImageLayer using the postcode layer source.
const tutorPostcodeLayer = new ImageLayer({
  source: tutorPostcodeSource
});

const rmPostcodeSource = new ImageWMS({
  url: 'https://geo.flixprimeplus.net/geoserver/ps-data-postgis/wms',
  params: {'LAYERS': 'ps-data-postgis:pspostcode-rm-uk_v', 'TILED': true, CQL_FILTER: "tutorname >= 'A'"},
  serverType: 'geoserver',
  crossOrigin: 'anonymous'
})

const rmPostcodeLayer = new ImageLayer({
  source: rmPostcodeSource
})

//Instantiates a group layer to hold the postcode layer and attaches
//it to the map.

const mapContentGroup = new Group({
  title: 'Map Content',
  layers: [
    rmPostcodeLayer,
    tutorPostcodeLayer
  ]
})
map.addLayer(mapContentGroup);

let popup, popupCloser, popupContent 

//An async function that creates a popu-up and returns the following:
// Popup, Popup Closer, Popup Content
async function createPopUp(){
  popup = document.createElement('div');
  popup.className = 'popup';

  popupCloser = document.createElement('a');
  popupCloser.className = 'popup-closer';

  popupContent = document.createElement('div');
  popupContent.className = 'popup-content';

  popup.appendChild(popupCloser);
  popup.appendChild(popupContent);

}

createPopUp()

//Instantiates an overlay to anchor the popup to the map.
const popupOverlay = new Overlay({
  element: popup,
  autoPan: true,
  autoPanAnimation: {
    duration: 250
  }
});

//Adds the overlay to the map.
map.addOverlay(popupOverlay);

//Adds a click event to the popupCloser
popupCloser.addEventListener('click', function() {
  popupOverlay.setPosition(undefined);
  popupCloser.blur();
  return false;
});


//Adds a click event to the map.
map.on('singleclick', function(evt) {
  const coordinates = evt.coordinate
  const viewResolution = view.getResolution();

  popupContent.innerHTML = '';

  const url = tutorPostcodeSource.getFeatureInfoUrl(
    evt.coordinate,
    viewResolution,
    'EPSG:3857',
    {'INFO_FORMAT': 'application/json'},
  );
  console.log("Getting here?")
  if (url) {
    fetch(url)
    .then((response) => response.json())
    .then((html) => {
      if (html.features.length > 0) {
        console.log(html.features[0].properties)
        const popupTitle = document.createElement('div');
        popupTitle.className = 'popup-title';

        if (applicationType[0].application === 'viewer') {
        popupTitle.innerHTML = `Postcode Viewer`;
        } else if (applicationType[0].application === 'editor') {
        popupTitle.innerHTML = `Postcode Editor`;
        }
        popupContent.appendChild(popupTitle);

        const popupPostcodeRow = document.createElement('div');
        popupPostcodeRow.className = 'pop-up-row';
        popupContent.appendChild(popupPostcodeRow);

        const popupPostcodeLabel = document.createElement('div');
        popupPostcodeLabel.className = 'popup-postcode-label';
        popupPostcodeLabel.innerHTML = 'Postcode:';
        popupPostcodeRow.appendChild(popupPostcodeLabel);

        const popupPostcode = document.createElement('input');
        popupPostcode.className = 'popup-postcode';
        popupPostcode.value = html.features[0].properties.postdist;
        popupPostcode.disabled = true;
        popupPostcodeRow.appendChild(popupPostcode);

        const tutorNameRow = document.createElement('div');
        tutorNameRow.className = 'pop-up-row';
        popupContent.appendChild(tutorNameRow);

        const tutorNameLabel = document.createElement('div');
        tutorNameLabel.className = 'tutor-name-label';
        tutorNameLabel.innerHTML = 'Tutor Name(s):';
        tutorNameRow.appendChild(tutorNameLabel);

        const tutorName = document.createElement('input');
        tutorName.className = 'tutor-name';
        tutorName.value = html.features[0].properties.tutorname;
        tutorName.disabled = true;
        tutorNameRow.appendChild(tutorName);

        const tutorCommentsRow = document.createElement('div');
        tutorCommentsRow.className = 'pop-up-row';
        popupContent.appendChild(tutorCommentsRow);
        
        const tutorCommentsLabel = document.createElement('div');
        tutorCommentsLabel.className = 'tutor-comments-label';
        tutorCommentsLabel.innerHTML = 'Postcode Comments:';
        tutorCommentsRow.appendChild(tutorCommentsLabel);

        const tutorComments = document.createElement('textarea');
        tutorComments.className = 'tutor-comments';
        tutorComments.value = html.features[0].properties.pccomment;
        tutorComments.disabled = true;
        tutorCommentsRow.appendChild(tutorComments);

        const tutorRgManagerRow = document.createElement('div');
        tutorRgManagerRow.className = 'pop-up-row';
        popupContent.appendChild(tutorRgManagerRow);

        const tutorRgManagerLabel = document.createElement('div');
        tutorRgManagerLabel.className = 'tutor-rg-manager-label';
        tutorRgManagerLabel.innerHTML = 'Regional Manager:';
        tutorRgManagerRow.appendChild(tutorRgManagerLabel);

        const tutorRgManager = document.createElement('input');
        tutorRgManager.className = 'tutor-rg-manager';
        tutorRgManager.value = html.features[0].properties.rgmanager;
        tutorRgManager.disabled = true;
        tutorRgManagerRow.appendChild(tutorRgManager);

        const tutorActiveRow = document.createElement('div');
        tutorActiveRow.className = 'pop-up-row';
        popupContent.appendChild(tutorActiveRow);

        const tutorActiveLabel = document.createElement('div');
        tutorActiveLabel.className = 'tutor-active-label';
        tutorActiveLabel.innerHTML = 'Active:';
        tutorActiveRow.appendChild(tutorActiveLabel);

        const tutorActive = document.createElement('input');
        tutorActive.type = 'checkbox';
        tutorActive.disabled = true;
        tutorActive.className = 'tutor-active';
        if (html.features[0].properties.active == 'Y'){
          tutorActive.checked = true;
        } else {
          tutorActive.checked = false;
        }
        tutorActiveRow.appendChild(tutorActive);

        popupOverlay.setPosition(coordinates);

        if (applicationType[0].application === 'editor'){

          const tutorRestingRow = document.createElement('div');
          tutorRestingRow.className = 'pop-up-row';
          popupContent.appendChild(tutorRestingRow);

          const tutorRestingLabel = document.createElement('div');
          tutorRestingLabel.className = 'tutor-resting-label';
          tutorRestingLabel.innerHTML = 'Resting:';
          tutorRestingRow.appendChild(tutorRestingLabel);

          const tutorResting = document.createElement('input');
          tutorResting.type = 'checkbox';
          tutorResting.disabled = true;
          tutorResting.className = 'tutor-resting';
          if (html.features[0].properties.resting == 'Y'){
            tutorResting.checked = true;
          } else {
            tutorResting.checked = false;
          }
          tutorRestingRow.appendChild(tutorResting);
          
          const tutorRestingCommentsRow = document.createElement('div');
          tutorRestingCommentsRow.className = 'pop-up-row';
          popupContent.appendChild(tutorRestingCommentsRow);

          const tutorRestingCommentsLabel = document.createElement('div');
          tutorRestingCommentsLabel.className = 'tutor-resting-comments-label';
          tutorRestingCommentsLabel.innerHTML = 'Resting Comments:';
          tutorRestingCommentsRow.appendChild(tutorRestingCommentsLabel);

          const tutorRestingComments = document.createElement('textarea');
          tutorRestingComments.className = 'tutor-resting-comments';
          tutorRestingComments.value = html.features[0].properties.rtcomment;
          tutorRestingComments.disabled = true;
          tutorRestingCommentsRow.appendChild(tutorRestingComments);

          //add a button container and append three buttons, Start Editing, Save and Cancel
          const buttonContainer = document.createElement('div');
          buttonContainer.className = 'button-container';
          popupContent.appendChild(buttonContainer);

          const startEditingButton = document.createElement('button');
          startEditingButton.className = 'start-editing-button';
          startEditingButton.innerHTML = 'Start Editing';
          buttonContainer.appendChild(startEditingButton);

          const saveButton = document.createElement('button');
          saveButton.className = 'save-button';
          saveButton.innerHTML = 'Save';
          saveButton.disabled = true;
          saveButton.style = 'display: none';
          buttonContainer.appendChild(saveButton);

          const cancelButton = document.createElement('button');
          cancelButton.className = 'cancel-button';
          cancelButton.innerHTML = 'Cancel';
          cancelButton.disabled = true;
          cancelButton.style = 'display: none';
          buttonContainer.appendChild(cancelButton);

          //append to the popup content
          popupContent.appendChild(buttonContainer);

          //add a click event to the start editing button
          startEditingButton.addEventListener('click', function() {
            tutorName.disabled = false;
            tutorComments.disabled = false;
            tutorRgManager.disabled = false;
            tutorActive.disabled = false;
            tutorResting.disabled = false;
            tutorRestingComments.disabled = false;

            saveButton.disabled = false;
            cancelButton.disabled = false;
            saveButton.style = 'display: inline-block';
            cancelButton.style = 'display: inline-block';
            startEditingButton.style = 'display: none';

            tutorName.focus();
        });

        saveButton.addEventListener('click', function() {
          console.log("Save Button clicked");
          startEditingButton.style = 'display: inline-block';
          saveButton.style = 'display: none';
          cancelButton.style = 'display: none';

          tutorName.disabled = true;
          tutorComments.disabled = true;
          tutorActive.disabled = true;
          tutorRgManager.disabled = true;
          tutorResting.disabled = true;
          tutorRestingComments.disabled = true;

          let tutorNameValue = tutorName.value;
          let tutorCommentsValue = tutorComments.value;
          let tutorActiveValue = tutorActive.checked;
          let tutorRgManagerValue = tutorRgManager.value;
          let tutorRestingValue = tutorResting.checked;
          let tutorRestingCommentsValue = tutorRestingComments.value;


          if (tutorActiveValue == true){
            tutorActiveValue = 'Y';
          } else {
            tutorActiveValue = 'N';
          }

          if (tutorNameValue == ""){
            tutorNameValue = 'Open';
          }

          if (tutorRestingValue == true){
            tutorRestingValue = 'Y';
          } else {
            tutorRestingValue = 'N';
          }

          const fid = html.features[0].properties.distid;

          const values = 
          [tutorNameValue, tutorCommentsValue, tutorActiveValue, tutorRgManagerValue, tutorRestingValue, tutorRestingCommentsValue, fid];
          
          console.log(values);

          fetch("https://psdata.flixprimeplus.net/update",
          {
            method: "POST",
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
          }).then(response => {
            console.log(response)
            
            tutorPostcodeSource.refresh()
            tutorPostcodeLayer.getSource().updateParams({"time": Date.now()});
            popupOverlay.setPosition(undefined);
            popupCloser.blur();
          }
          )
        
      });

       //add a click event to the cancel button
       cancelButton.addEventListener('click', function() {
        startEditingButton.style = 'display: inline-block';
        saveButton.style = 'display: none';
        cancelButton.style = 'display: none';

        //reset the inputs to the default values
        tutorName.value = html.features[0].properties.tutorname;
        tutorComments.value = html.features[0].properties.pccomment;
        tutorRgManager.value = html.features[0].properties.rgmanager;
        if (html.features[0].properties.active == 'Y'){
          tutorActive.checked = true;
        } else {
          tutorActive.checked = false;
        }
        if (html.features[0].properties.resting == 'Y'){
          tutorResting.checked = true;
        } else {
          tutorResting.checked = false;
        }
        tutorRestingComments.value = html.features[0].properties.rtcomment;

        tutorName.disabled = true;
        tutorComments.disabled = true;
        tutorActive.disabled = true;
        tutorRgManager.disabled = true;
        tutorResting.disabled = true;
        tutorRestingComments.disabled = true;
      });


      }

    } else if (html.features.length == 0){
      popupOverlay.setPosition(undefined);
      popupCloser.blur();
    }
  }
    );
  }}
);

async function createTitleBar(){
  const titleBar = document.createElement('div');
  titleBar.className = 'title-bar';
  const titleLogo = document.createElement('img');
  titleLogo.src = 'https://i.vimeocdn.com/portrait/17855477_60x60.jpg';
  titleLogo.className = 'title-logo';
  titleBar.appendChild(titleLogo);
  const titleText = document.createElement('div');
  titleText.className = 'title-text';
  if (applicationType[0].application === 'editor'){
    titleText.innerHTML = 'Puppy School Web GIS Portal - Tutor Postcode Editor';
  } else if (applicationType[0].application === 'viewer'){
    titleText.innerHTML = 'Puppy School Web GIS Portal - Tutor Postcode Viewer';
  }
  titleBar.appendChild(titleText);

  const myControl = new Control({element: titleBar});
  map.addControl(myControl);
}
createTitleBar()

//create a new control to hold the the button for Regional Manager layer.

rmPostcodeLayer.setVisible(false);

const buttonContainer = document.createElement('div');
buttonContainer.className = 'rm__button-container';

const button = document.createElement('button');
button.className = 'rm__button';
button.innerHTML = 'Regional Manager Layer';
buttonContainer.appendChild(button);

const myControl = new Control({element: buttonContainer});
map.addControl(myControl);

//add a click event to the button
button.addEventListener('click', function() {
  if (tutorPostcodeLayer.getVisible() == true){
    console.log('PS layer is not visible')
    tutorPostcodeLayer.setVisible(false);
    rmPostcodeLayer.setVisible(true);
    button.className = 'rm__button-active';
  } else if (tutorPostcodeLayer.getVisible() == false){
    console.log("RM Layer is not visible");
    tutorPostcodeLayer.setVisible(true);
    rmPostcodeLayer.setVisible(false);
    button.className = 'rm__button';
  }
}
);

//Add a modal to the page that tells the user that the first load may take a few seconds. Also tell the user that they can interact with every postcode on the map.
//That appears for only 5 seconds but can be closed by the user.

const modal = document.createElement('div');
modal.className = 'modal';
const modalContent = document.createElement('div');
modalContent.className = 'modal-content';
const modalText = document.createElement('div');
modalText.className = 'modal-text';
modalText.innerHTML = '<h2>Welcome to the Tutor Postcode Viewer</h2><br> Please note that the first load may take a few seconds.<br><br>You can interact with every postcode on the map just by clicking on it.<br><br> You can also toggle the Regional Manager View by clicking the button on the left hand side.';
modalContent.appendChild(modalText);
const modalClose = document.createElement('div');
modalClose.className = 'modal-close';
modalClose.innerHTML = 'X';
modalContent.appendChild(modalClose);
modal.appendChild(modalContent);
const modalControl = new Control({element: modal});
map.addControl(modalControl);

//add a click event to the close button
modalClose.addEventListener('click', function() {
  modal.style = 'display: none';
}
);

//add an event listener to close the modal

modal.addEventListener('click', function() {
  modal.style = 'display: none';
}
);
